import React, { Component, Fragment } from 'react';

import {
    Link,
} from 'gatsby';


const Footer = () => {

    return (
        <div className="site-footer">
            <h4 className="text-center">
                © {new Date().getFullYear()} 
          {` - `}
                <a href="https://www.gatsbyjs.org">Tasaciones.org</a>
            </h4>
            <span className="d-flex justify-content-center">
                <Link className="mx-2" to="/aviso-legal">Aviso Legal</Link>
                <Link className="mx-2" to="/politica-privacidad">Política de privacidad</Link>
                <Link className="mx-2" to="/politica-cookies">Política de Cookies</Link>
            </span>
        </div>
    );
}

export default Footer